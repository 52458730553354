import React, { useState } from 'react'

import './form.css'
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

//Specific way Zendesk wants to receive the data
const formatPayload = (subject, email, defaultName, feedback, pageURL) => {
  const requester = email ? { name: defaultName, email } : { name: defaultName }

  const comment = pageURL
    ? {
        body: `${feedback} ${pageURL}`,
      }
    : { body: feedback }

  return {
    request: {
      requester,
      subject,
      comment,
    },
  }
}

const Form = () => {
  const [details, setDetails] = useState({
    name: '',
    email: '',
    category: '',
    order_no: '',
    message: '',
  })

  const [thanks, setThanks] = useState(false)

  const [errors, setErrors] = useState({
    errorName: '',
    errorEmail: '',
    errorMessage: '',
  })

  const handleChange = e => {
    setDetails({ ...details, [e.target.name]: e.target.value })

    setErrors({
      errorName: '',
      errorEmail: '',
      errorMessage: '',
    })
  }

  function handleValidation() {
    let formIsValid = true
    let message = details.message

    if (!message) {
      formIsValid = false
      setErrors({ errorMessage: 'Please let us know how we can help you?' })
    }

    return formIsValid
  }

  const handleSubmit = e => {
    e.preventDefault()

    const subject = `Support request - Category: ${
      details.category
    } | Order No: ${details.order_no ? details.order_no : 'not provided'}`

    if (handleValidation()) {
      fetch(`https://twinnltd.zendesk.com/api/v2/requests.json`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          formatPayload(subject, details.email, details.name, details.message)
        ),
      })
        .then(response => {
          setThanks(!thanks)
        })
        .catch(error => alert(error))
    }
  }

  return (
    <>
      {thanks ? (
        <div className="thanks">
          <h1>Thank you!</h1>
          <br />
          <h2 style={{ textAlign: 'center' }}>Someone will be in touch.</h2>
        </div>
      ) : (
        <>
          <form method="post" onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <div className="input-box" style={{ gridArea: 'name' }}>
              <input
                type="text"
                name="name"
                placeholder="Name:"
                required
                onChange={handleChange}
                value={details.name}
              />
            </div>
            <div className="input-box" style={{ gridArea: 'email' }}>
              <input
                type="email"
                name="email"
                placeholder="Email:"
                required
                value={details.email}
                onChange={handleChange}
              />
            </div>
            <div className="input-box" style={{ gridArea: 'category' }}>
              <label>Choose a category:</label>
              <select
                type="select"
                name="category"
                onChange={handleChange}
                value={details.category}
              >
                <option value="Returns">Returns</option>
                <option value="Shipping">Shipping</option>
                <option value="Sizing">Sizing</option>
                <option value="Transactions">Transactions</option>
                <option value="Activation">Activation</option>
                <option value="I’ve got a different question">
                  I’ve got a different question
                </option>
              </select>
            </div>
            <div className="input-box" style={{ gridArea: 'ref' }}>
              <label>
                If you are enquiring about returns/orders, please include any
                ref/no
              </label>
              <input
                type="text"
                name="order_no"
                placeholder="Order No:"
                onChange={handleChange}
                value={details.order_no}
              />
            </div>
            <div
              className="input-box"
              style={{ gridArea: 'message', height: '100%' }}
            >
              <textarea
                type="text"
                name="message"
                placeholder="Message:"
                rows="5"
                onChange={handleChange}
                value={details.message}
              />
              <label>{errors.errorMessage}</label>
            </div>

            <button
              role="button"
              aria-label="Submit"
              className="submit"
              type="submit"
            >
              <h4>Submit</h4>
            </button>
          </form>
        </>
      )}
    </>
  )
}

export default Form
