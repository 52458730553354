import React from 'react'
export const BackButton = ({ button_text, link }) => {
  return (
    <a
      href={link}
      style={{
        color: 'var(--dark-blue)',
        cursor: 'pointer',
      }}
    >
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <h3 style={{ fontWeight: '700' }}>{button_text}</h3>
        <svg
          width="42"
          height="24"
          viewBox="0 0 42 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.00195312 12.3053H39.5M39.5 12.3053L26.7805 0.924591M39.5 12.3053L26.7805 23.0166"
            stroke="#081F31"
            strokeWidth="2.5"
          />
        </svg>
      </span>
    </a>
  )
}
