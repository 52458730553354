import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'

export const Unroll = ({ children, title }) => {
  const [unroll, setUnroll] = useState(false)
  const style = useSpring({
    height: unroll ? '100%' : '0px',
  })

  return (
    <div key={title} className="indent-left">
      <span className="question">
        <button onClick={() => setUnroll(!unroll)}>
          {unroll ? <h3>-</h3> : <h3>+</h3>}
        </button>
        <h3>{title}</h3>
      </span>
      <div className="indent-left">
        <animated.div className="unroll--child" style={style}>
          {children}
        </animated.div>
      </div>
    </div>
  )
}
